.attributes {
  .attributesHeader {
      border-radius: 14px 14px 0 0;
      background: #e71316;
      padding: 20px 20px 15px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .whiteGlove {
    background: url(../../assets/images/icn-crown.png) no-repeat;
    width: 36px;
    height: 25px;
  }
  .topContainer {
    margin: 0 20px;
  }
  .attributesBody {
      background: #fff;
      border-radius: 0 0 14px 14px;
      border: #e71316 solid 1px;
      //border-bottom:#e71316 solid 4px;
      padding-bottom: 10px;
  }

  .horizontalDivider{
    background-color: #e71316;//grey;
    //height:10px;
  }
  .segmentSection {
    padding: 20px 0;
      display: flex;
      border-top: 1px solid #e2e3e4;
  }
  .segment {
    flex: 50%;
    max-width: 50%;
    box-sizing: border-box;
      border-right: 1px solid #e2e3e4;
      &:last-child {
        border-right: 0 none;
      }
  }
  .groupTopSection {
    background-color:#f2f0f0;// ; #f2f0f0 //grey;
    //padding: 20px 0;//damodhar
    padding: 0;//3px 0;
    //padding-bottom:5px;
    display: flex;
    }
    .borderBottom{
      border-bottom: 1px solid #e2e3e4;
    }
    
    /*.innerLayout {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      box-sizing: border-box;
      border-right: 1px solid #e2e3e4;
      &:last-child {
        border-right: 0 none;
      }
    }*/

    .innerLayout{
      width: 33%;
      height: 85px;
      margin-right:4px;
      padding: 3% 4%;
      background-color: white;
      box-sizing: border-box;
      &:last-child {
        margin-right:0px;
      }
    }
    
    .bodyTitle {
      color: #78797c;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 5px;
      display: block;
      text-align: center;
    }
    
    .bodyValue {
      color: #54585a;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    
}