.orderContainer {
    padding: 18px;
}
.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .label {
        font-size: 12px;
        color: #78797c;
    }
    .value {
        font-size: 18px;
        color: #54585a;
        //font-weight: 800;
    }
}
.footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .color {
        width: 11px;
        height: 11px;
        border-radius: 1rem;
        background-color: #9bd3de;
        margin-bottom: 5px;
        margin-right: 10px;
    }
    .label {
        font-size: 14px;
        color: #78797c;
        margin-left: 10px;
    }
    .value {
        font-size: 20px;
        color: #54585a;
    }
}
.barProgress {
    height: 40px;
    background-color: #e2e3e4;
    border-radius: 10px;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
    .myBar {
        width: 10%;
        height: 40px;
        background-color: #9bd3de;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .myBarFull {
        width: 10%;
        height: 40px;
        background-color: #9bd3de;
        border-radius: 10px;
    }
}