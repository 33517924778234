.container {
  display: inline-block;
  width: 100%;
  min-height: calc(100vh - 106px);
}
.productsContainer {
  padding: 0 20px;
  display: flex;
}

.scrollStartParent{
  display:grid;
  position: relative;
  height: 150px;
  width: 10px;

  .rightBUArrow{
    position: absolute;
    top: calc(50% - 25px);
    right: 0px;
    top: 82px;
    background-color: rgba(0, 0, 0, .2);
    //background-color: #e71316;
    //color: #fff;
    color: #333;
    border-radius: 10px;
    //z-index:5;
    margin-right:20px;
  }

  .rightBUArrow:hover{
    background-color: #78797c;
    color:#fff;
  }
}

.scrollEndParent{
    display:grid;
    position: relative;
    height: 150px;
    //width: 10px;

   .leftBUArrow{
    position: absolute;
    top: calc(50% - 25px);
    left: 0px;//10px;
    top: 83px;
    background-color: rgba(0, 0, 0, .2);
    color: #333;
    //background-color: #e71316;
    //color: #fff;
    border-radius: 10px;
    z-index:5;
    margin-right:20px;
   }
   .leftBUArrow:hover{
     background-color: #78797c;
     color:#fff;
    //background-color: #e71316;
    //color: #fff;
   }
}


.productHead {
  padding: 0;
  display: flex;
  align-items: center;
}

.customerLayoutBx {
  padding: 20px 0;
  float: left;
  width: 100%;
}

.backBtn {
  border: 1px solid #78797c;
  border-radius: 5px;
  padding: 7px 8px 4px 20px;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  &:after {
    border-right: 5px solid #78797c;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: "";
    position: absolute;
    left: 9px;
    top: 10px;
  }
}

.groupSessionBx {
  padding: 0 0 20px 20px;
  width: 27%;
  float: left;
  .cardContainer {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      right: -12px;
      top: calc(50% - 7px);
      border-left: 14px solid #fff;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
    }
  }
}

.scrollStartParent{
  display:grid;
  position: relative;
  height: 150px;
  width: 10px;
  .scrollToStart{
    position: absolute;
    top: calc(50% - 25px);
    right: -10px;
    top: 92px;
    background-color: rgba(0, 0, 0, .2);
    //background-color: #e71316;
    //color: #fff;
    color: #333;
    border-radius: 10px;
    //z-index:5;
    margin-right:20px;
  }

  .scrollToStart:hover{
    background-color: #78797c;
    color:#fff;
  }
}

  .scrollEndParent{
    display:grid;
    position: relative;
    height: 150px;
    //width: 10px;

  .scrollToEnd{
   position: absolute;
   top: calc(50% - 25px);
   left: -3px;
   top: 92px;
   background-color: rgba(0, 0, 0, .2);
   //background-color: #e71316;
   color: #333;
   border-radius: 10px;
   z-index:5;
   margin-right:20px;
  }
  .scrollToEnd:hover{
    background-color: #78797c;
    color:#fff;
  }
 }

 .buttonStyle{
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: rgba(103, 58, 183, 0.1);
    color: #333;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding-left: 20px;
    margin-left: 1px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
 }

.productBox {
  width: 100%;
}
