
.favorite{
&.favoriteUnpin{
  background: url(../../assets/images/btn-unpin.png) no-repeat;
  content: "";
  position: relative;
  //left: 0;
  right: 13px;
  top: 0px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
&.favoritePin{
  background: url(../../assets/images/btn-pin.png) no-repeat;
  content: "";
  position: relative;
  //left: 0;
  right: 13px;
  top: 0px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
&.tooltip {
  position: relative;
  display: inline-block;
}

&.tooltip .tooltiptext {
    visibility: hidden;
    width: 145px;
    background-color: black;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 110%;
    opacity: 0.9;
    font-family: "Helvetica";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
&.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
&.tooltip:hover .tooltiptext {
  visibility: visible;
}
}