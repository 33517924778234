.itemId{
    display:inline-block;
    //width:93px;//damodhar
    width: 25%;
    text-align: right;
    vertical-align: text-top;
}
.itemName{
    display:inline-block;
    width:75%;
    //width: 268px;//damodhar
    vertical-align: text-top;
    padding-left: 5px;
}