.navBg {
  background: #e71316;
  position: absolute;
  width: 83px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
  transition: all 0.2s ease;
  // commenting below menu hover
  // &:hover {
  //   width: 253px;
  //   ul li a {
  //     font-size: 16px;
  //     color: #fff;
  //   }
  // }
}

.navBg ul {
  list-style: none;
  padding: 0;
  margin: 100px 0 0;
  display: flex;
  flex-direction: column;
  font-family: "Helvetica";
  li {
    a {
      font-size: 0;
      float: left;
      padding: 15px 0 15px 30px;
      margin-bottom: 20px;
      position: relative;
      text-decoration: none;
      outline: none;
      display: flex;
      flex-direction: row;
      span {
        line-height: 23px;
      }
      &.active:after,
      &:hover:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 6px solid #fff;
        border-radius: 0 12px 12px 0;
      }
      i {
        width: 23px;
        height: 23px;
        float: left;
        margin-right: 20px;
        cursor: pointer;
        &.dashboard-icn {
          background: url(../../assets/images/icn-dashboard-selected.png)
            no-repeat;
        }
        &.feedback-icn {
          background: url(../../assets/images/feedback.png)
            no-repeat;
            width: 24px;
            height: 24px;
        }
        &.info-icn {
          background: url(../../assets/images/information.png)
            no-repeat;
            width: 24px;
            height: 24px;
        }
        &.gene-icn {
          background: url(../../assets/images/gene.png)
            no-repeat;
            width: 24px;
            height: 30px;
        }
        &.ambi-icn {
          background: url(../../assets/images/ambiguity.png)
            no-repeat;
            width: 24px;
            height: 24px;
        }
        &.view-icn {
          background: url(../../assets/images/icn-cc-oview.png) no-repeat;
        }
        &.summary-icn {
          background: url(../../assets/images/icn-c-xsummary.png) no-repeat;
        }
        &.revenue-icn {
          background: url(../../assets/images/icn-revenue.png) no-repeat;
        }
        &.account-icn {
          background: url(../../assets/images/icn-hierarchies.png) no-repeat;
        }
      }
    }
  }
}
