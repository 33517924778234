.cardContainer {
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  font-family: "Helvetica";
  width: calc(100% - 20px);
  margin-right: 20px;
  position: relative;
  &.cardMargin {
    margin: 0 10px;
  }
  &.cardAfter {
    margin-top: 10px;
    width: 300px;
  }
  &.cardAfter::after {
    content: "";
    position: absolute;
    right: -12px;
    top: calc(50% - 7px);
    border-left: 14px solid #fff;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
  }
  &:focus {
    outline: 0 none;
  }
  .cardHeader {
    padding: 10px;
    border-bottom: 1px solid #e2e3e4;
    display: flex;
    justify-content: space-between;
    .cardTitle {
      font-size: 14px;
      font-weight: bold;
      color: #54585a;
      line-height: 1;
    }
    .logoIcn {
      background-color: #78797c;
      width: 32px;
      height: 32px;
      border-radius: 1rem;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
    .headerLeft {
      display: flex;
      align-items: center;
      .product-logo {
        background: url(../../assets/images/company-logo-1.png) no-repeat center;
        border-radius: 11.5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #e2e3e4;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        float: left;
      }
      .productTitle {
        font-size: 16px;
        font-weight: bold;
        color: #54585a;
      }
    }
    .headerRight {
      flex: 1;
      .innerTitle {
        font-size: 12px;
        font-weight: 500;
        color: #98999c;
        display: block;
        text-align: right;
      }
      .innerValue {
        display: block;
        color: #4aa707;
        font-size: 16px;
        font-weight: 500;
        text-align: right;
      }
    }
    .title {
      font-size: 20px;
      font-family: "Helvetica";
      font-weight: 600;
      color: #54585a;
      background: url(../../assets/images/company-logo-1.png) no-repeat;
      padding-left: 35px;
    }
  }
  .cardFooter {
    padding: 5px 0;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0 0 14px 14px;
  }
  .cardBody {
    padding: 8px 10px;
    display: flex;
    .innerLayout {
      //flex: 0 0 33.3333%;
      //max-width: 33.3333%;
      flex: 0 0 50%;
      max-width: 50%;
      box-sizing: border-box;
      border-right: 1px solid #e2e3e4;
      &:last-child {
        border-right: 0 none;
      }
      .bodyTitle {
        color: #78797c;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        display: block;
        text-align: center;
      }
      .bodyValue {
        font-size: 18px;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        text-align: center;
        color: #54585a;
        .arrowUp,
        .arrowDown {
          background: url(../../assets/images/up-arrow.png) no-repeat;
          width: 8px;
          height: 14px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowNoChange {
          background: url(../../assets/images/no-change-arrow.png) no-repeat;
          width: 14px;
          height: 11px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowDown {
          transform: rotate(180deg);
        }
      }
    }
    .outerSegment {
      display: flex;
      margin-bottom: 30px;
      .leftSeg,
      .rightSeg {
        flex: 0 0 50%;
        label {
          display: flex;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          color: #98999c;
        }
        span {
          font-size: 20px;
          font-family: "Helvetica";
          font-weight: 600;
          color: #54585a;
        }
      }
    }
    .segment {
      flex: 0 0 50%;
      label {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color: #98999c;
      }
      span {
        font-size: 20px;
        font-family: "Helvetica";
        font-weight: 600;
        color: #4aa707;
      }
    }
    .progressSec {
      margin-bottom: 30px;
    }
    .progressTitle {
      font-size: 14px;
      font-weight: 500;
      color: #98999c;
    }
    .myProgress {
      width: 80%;
      background-color: #e2e3e4;
      border-radius: 4px;
      height: 8px;
      margin: 16px 0 0;
      display: inline-flex;
      .myBar {
        width: 1%;
        height: 8px;
        background-color: #4aa707;
        border-radius: 4px;
      }
    }
    .myProgressCount {
      width: 20%;
      display: inline-flex;
      padding-left: 10px;
      color: #54585a;
      font-size: 20px;
      font-family: "Helvetica";
      font-weight: 600;
    }
  }
}
.dash {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #54585a;
  font-family: "NeueHelvetica";
  letter-spacing: 0.5px;
}
