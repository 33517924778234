.orderContainer {
    padding: 10px;
}
.header {
    display: flex;
    justify-content: space-between;
    padding-top:20px;
    margin-bottom: 20px;
    .label {
        font-size: 12px;
        color: #78797c;
        margin-right:5px;
    }
    .value {
        font-size: 18px;
        color: #54585a;
    }
    .color {
        width: 11px;
        height: 11px;
        border-radius: 1rem;
        background-color: #9bd3de;
        margin-top: 9px;
        margin-right: 5px;
        float: left;
    }
    
}
.footer {
    padding-top: 30px;
}

.barProgress {
    height: 40px;
    background-color: #e2e3e4;
    border-radius: 10px;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
    .myBar {
        width: 10%;
        height: 40px;
        background-color: #820000;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .myBarFull {
        width: 10%;
        height: 40px;
        background-color: #820000;
        border-radius: 10px;
    }
}