
.chartOuter {
  display: flex;
  padding: 18px 5px 18px 5px;
  height: auto;
  max-height: 400px;;
}

.chartLeft {
  align-self: center;
}

.chartLegend {
  align-self: center;
  overflow-y: auto;
  max-height: 400px;
  height: auto;
  width:58%;
  ul {
    margin: 0;
    padding: 0 0 0 5px;
    list-style: none;
    li {
      margin-bottom: 15px;
      display: flex;
      align-items: baseline;
      padding-left: 13px;
      position: relative;
      .listDot {
        width: 11px;
        height: 11px;
        border-radius: 1rem;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .labelValue {
        font-size: 15px;
        color: #506883;
        text-align: left;
        //width: 80px;
        width: 45%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .labelName {
        color: #78797c;
        font-size: 15px;
        margin: 0 5px;
        //width: 60px;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .labelIncrease {
        font-size: 14px;
        font-weight: bold;
        color: #4aa707
      }
      .labelDecrease {
        font-size: 14px;
        font-weight: bold;
        color: #d01214
      }
      .labelUpArrow {
        border-bottom: 6px solid #4aa707;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        margin: 0 0 0 5px;
      }
      .labelDownArrow {
        border-top: 6px solid #d01214;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        margin: 0 0 0 5px;
      }
    }
  }
}
