.container {
    //width: 280px;//commented for Search box changes
    width:285px;//added for Search box changes
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    position: absolute;
    z-index: 1;
    //right: 223px;// commented for Search box changes
    right:277px;
    top: 90px;
    border-radius: 10px;
    .header {
        padding: 20px 40px;
        border-bottom: 1px solid #e2e3e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .cancel {
        cursor: pointer;
        color: #429ad6;
        font-size: 14px;
    }
    .customerItem {
        border-bottom: 1px solid #e2e3e4;
        padding: 10px 2px 10px 10px;;
        cursor: pointer;
    }
    .customerItem:hover {
        background-color:#f2f0f0;
    }
    .customerItem:last-child {
        border-bottom: none;
    }
    .label {
        color: #78797c;
        font-size: 14px;
    }
    .result {
        max-height: 300px;
        overflow-y: auto;
        .customer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .searchItem {
                flex: 1;
                font-size: 14px;
                .value {
                    padding: 0 5px;
                    .arrowUp,
                    .arrowDown {
                    background: url(../../assets/images/up-arrow.png) no-repeat;
                    width: 8px;
                    height: 11px;
                    display: inline-block;
                    margin-left: 5px;
                    }
                    .arrowNoChange {
                    background: url(../../assets/images/no-change-arrow.png) no-repeat;
                    width: 14px;
                    height: 11px;
                    display: inline-block;
                    margin-left: 5px;
                    }
                    .arrowDown {
                    transform: rotate(180deg);
                    }
                }
                .red {
                    color: #d01214;
                }
                .green {
                    color: #4aa707;
                }
                .dash {
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: #54585a;
                    font-family: "NeueHelvetica";
                    letter-spacing: 0.5px;
                    padding: 10px;
                }
            }
        }
    }
    .nodata {
        padding: 20px;
        text-align: center;
        font-size: 14px;
    }
    .footer {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        border-top: 1px solid #e2e3e4;
        span {
            cursor: pointer;
            color: #429ad6;
        }
    }
    .footer:hover {
        background-color:#f2f0f0;
    }
}
.container::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 51%;
    margin-left: -16px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  .toggleButton{
      width:50px;
      height:22px;
      border: 1px solid #ccc;
      border-radius: 25px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      .toggleCircle{
          width:20px;
          height:20px;
          position: absolute;
          right: 0;
          background: #d01214;
          border-radius: 50%;
          transition: 300ms;
      }
      .checked{
        width:20px;
        height:20px;
        position: absolute;
        background: #d01214;
        border-radius: 50%;
        right: 58%;
        transition: 300ms;
      }
  }
  .cross{
      width:25px;
      height: 25px;
      position: absolute;
      right: 5px;
      top:0px;
      bottom: 0;
      margin: auto;
      background: url(../../assets/images/close.png)no-repeat;
      background-size: auto 14px;
      background-position: center;
      cursor: pointer;
  }
  .pRight30{
    padding-right: 30px;
    }
